import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import songReducer from './slices/song';
import eventReducer from './slices/event';
import genresReducer from './slices/genre';

const rootPersistConfig = {
  key: 'root',
  storage,
  // keyPrefix: 'redux-',
  // whitelist: [],
};

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout'],
// };

const rootReducer = combineReducers({
  song: songReducer,
  event: eventReducer,
  genres: genresReducer,
});

export { rootPersistConfig, rootReducer };
